<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow3.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    Reflow system for maximum flexibility
                  </h2>
                  <br />
                  <h5>
                   The HOTFLOW 3 uses patented Ersa heating technology for optimum heat transfer with minimal energy and nitrogen consumption. In terms of productivity to footprint ratio, the Ersa reflow soldering system sets the industry standard. <br>
                   Double to quadruple transport options enable amazing throughput increases without additional floor space. With up to four transport speeds, precisely adjusted transport widths, a wide variety of assemblies can be processed on the system.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/divhotflow4.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br /><br /><br />
                <div class="mt-4">
                  <h2>Technology Highlights</h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Best temperature transmission for a wide variety of flat modules
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Residual oxygen control with low N2 consumption</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Low energy consumption through intelligent energy management</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Multistage controlled cooling
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Multi-stage process gas cleaning system</h5>
                    </li>
                    <br />
                    <li>
                      <h5>"On the fly" maintenance for increased machine availability</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                       Multitrack transport systems
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Ersa Process Control (EPC) for continuous process monitoring</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Ersa Autoprofiler software for instant temperature profile finding</h5>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br />
            <div>
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#fff"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <div class="carousel-item" active>
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hoflow31.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 3: Optimierter Reflow-Prozesstunnel: Prozessstabilität unter allen Auslastungssituationen
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow32.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 3: Leistungsstarke Kühltechnologie - aktive Kühlung für eine optimale Weiterverarbeitungstemperatur
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow33.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 3: Maintenance-on-the-Fly: höchste Maschinenverfügbarkeit durch größte Servicefreundlichkeit
                    </p>
                  </div>
                </div>
              
              
              </b-carousel>
              <br /><br />
              <!-- video -->
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6">
                          <!-- 16:9 aspect ratio -->
                          <div class="ratio ratio-21x9">
                            <iframe
                              style="width: 500px; height: 250px;"
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/_7KolIRmX0Q" 
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>

                        <div class="col-xl-6">
                          <div class="ratio ratio-21x9">
                            <iframe
                              style="width: 500px; height: 250px;"
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/iFKvei57rLc" 
                              allowfullscreen=""
                            ></iframe>
                          </div>
                          
                        </div>
                        <!-- end col -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>